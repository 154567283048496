import React, { useEffect, useRef, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Helmet } from 'react-helmet'

import Layout from '@components/layout'
import Title from '@components/title'
import Button from '@components/button'

import TopBanner from '@components/top-banner'
import ListBenefits from '@page/asukkaalle/list-benefits'
import TextWrapper from '@page/asukkaalle/text-wrapper'
import testImage from '@page/asukkaalle/images/test_image.jpg'

const Resident = ({ uri }) => {
  const contentRef = useRef(null)
  const [headings, setHeadings] = useState([])

  useEffect(() => {
    const content = contentRef.current
    const hTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
    const anchorHeadings = []

    hTypes.forEach(h => {
      const headings = Array.from(content.getElementsByTagName(h))
      headings.forEach(e => e.id && anchorHeadings.push(e))
    })

    setHeadings(anchorHeadings)
  }, [])

  return (
    <Layout currentLocation={uri}>
      <Helmet>
        <title>Asukkaalle | Tuulia Kodit</title>
      </Helmet>
      <TopBanner title="Tietoa" subtitle="asukkaalle" />

      <section className="section">
        <div className="container">
          <TextWrapper className="f9-sm f8-m f7-l mv0">
            Asukkaalle-osiosta voit löytää perustietoa vuokrasuhteeseen ja
            asumiseen liittyen. Sivuilta löytyvät myös taloyhtiöisi
            isännöitsijän sekä huoltoyhtiön yhteystiedot, sekä vuokra-asumiseen
            liittyvät usein kysytyt kysymykset.
          </TextWrapper>
        </div>
      </section>

      <section className="section section--gray section--bg" ref={contentRef}>
        <div className="container">
          {/* <Title
            heading="h2"
            className="mb5-sm mb6-m mb7-l"
            textCenter
            id="planning"
          >
            Planning to buy an investment home
          </Title> */}
          <div className="flex flex-wrap">
            <div className="order-2 order-1-l w-100 w-70-l pr4-l">
              <div className="text-section">
                <Title heading="h3" className="mb4" id="vuokrasopimus">
                  Vuokrasopimus ja vuokra
                </Title>

                <TextWrapper>
                  Vuokrasopimus on todistus vuokrasuhteesta. Se sitoo sekä
                  vuokralaista että vuokranantajaa koko sopimuksen
                  voimassaoloajan. Tutustu siis vuokrasopimukseen huolella ennen
                  sen allekirjoittamista, ja varmista, että olet ymmärtänyt
                  sopimuksen ehtojen mukaiset oikeutesi ja velvollisuutesi. Kysy
                  tarvittaessa lisätietoja!
                </TextWrapper>
                <TextWrapper>
                  Tuulia Kotien vuokrasopimukset ovat tyypillisesti toistaiseksi
                  voimassa olevia sopimuksia, joissa on ilmoitettu ensimmäinen
                  mahdollinen sopimuksen irtisanomispäivä. Toistaiseksi voimassa
                  oleva sopimus jatkuu sen irtisanomiseen asti. Mikäli sopimus
                  irtisanotaan muista kuin sopimuksessa ilmoitettujen
                  velvoitteiden laiminlyönnistä johtuen päättymään ennen
                  ensimmäistä mahdollista irtisanomispäivää, tulee irtisanovan
                  osapuolen suorittaa sopimukseen kirjattu sopimussakko.
                </TextWrapper>
                <TextWrapper>
                  Vuokrasopimuksesta löytyvät vuokranantajan ja vuokralaisen
                  tiedot, vuokrattavan asunnon tiedot, vuokran suuruus sekä
                  maksetun vakuusmaksun arvo. Vuokrasopimuksessa sovitaan myös
                  vuokrattavan kohteen kuukausivuokra sekä mahdolliset
                  käyttömaksut, joista yhdessä muodostuu vuokrattavan kohteen
                  kuukausittainen kokonaisvuokra. Vuokranmaksupäivä on kuukauden
                  toinen arkipäivä tai muu sopimukseen kirjattu päivä. Vuokra on
                  maksettava vuokranantajan toimittamin tiedoin tämän
                  määräämälle tilille ja toimitettuja viitetietoja käyttäen.
                  Myöhästyneistä maksuista veloitetaan perintämaksu ja
                  viivästyskorko. Vuokralaisella on vuokranmaksuvelvollisuus
                  sopimuksen koko voimassaolon ajan.
                </TextWrapper>
                <TextWrapper>
                  Tuulia Kotien kohteiden vuokra on tyypillisesti sidottu
                  elinkustannusindeksiin, jolloin kohteiden kuukausivuokra
                  muuttuu vuosittain elinkustannusindeksin muutoksia
                  vastaavasti. Vuokran tarkistuspäivä on 1. tammikuuta.
                </TextWrapper>
                <TextWrapper>
                  Autopaikasta voidaan tehdä erillinen vuokrasopimus, tai
                  autopaikan vuokrasopimus voidaan liittää varsinaisen
                  vuokrasopimuksen yhteyteen. Autopaikan vuokra lisätään asunnon
                  vuokralaskuun.
                </TextWrapper>
                <TextWrapper>
                  Käytännössä Tuulia Kotien vuokrasopimukset solmitaan
                  sähköisinä, jolloin tarvitset henkilöllisyyden varmentamiseen
                  ja vuokrasopimuksen allekirjoittamiseen pankkitunnukset.
                  Allekirjoitettu vuokrasopimus jää sähköiseen arkistoon myös
                  vuokralaisen käytettäväksi.
                </TextWrapper>
                <TextWrapper>
                  Tärkeimpiä AHVL:n vuokrasuhteeseen liittyviä säännöksiä voi
                  tarkastella <a href="#laki">tästä</a>.
                </TextWrapper>
              </div>

              {/* <div className="text-section">
                <Title heading="h3" className="mb3" noUnderline>
                  What is the Return on Investment?
                </Title>

                <div className="josefin mb4 text-separator">
                  <h6 className="gray-blue f6 fw5 mb3">
                    There are two sources of home investor returns:
                  </h6>
                  <ol className="f7 list list-decimal">
                    <li>rental income from the apartment</li>
                    <li>appreciation</li>
                  </ol>
                </div>

                <TextWrapper className="mb4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </TextWrapper>

                <TextWrapper>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </TextWrapper>
              </div> */}

              {/* <div className="text-section">
                <Title heading="h3" className="mb4">
                  Finding a good investment home
                </Title>

                <TextWrapper className="mb4">
                  Neque porro quisquam est, qui dolorem ipsum quia dolor sit
                  amet, consectetur, adipisci velit, sed quia non numquam eius
                  modi tempora incidunt ut labore et dolore magnam aliquam{' '}
                  <a href="#test">quaerat voluptatem.</a>
                </TextWrapper>

                <p className="fw5 josefin f8">
                  <span className="navy">
                    Lorem ipsum dolor sit amet, consectetur
                  </span>{' '}
                  adipisicing elit, sed do eiusmod tempor incididunt ut labore
                  et dolore magna aliqua ut enim ad minim veniam.
                </p>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4">
                  Is it worth buying an apartment from a broker or a private
                  seller?
                </Title>

                <TextWrapper className="mb4">
                  Anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                  error sit voluptatem accusantium doloremque laudantium, totam
                  rem aperiam, eaque ipsa quae ab illo inventore veritatis et
                  quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                  ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                  fugit, sed quia consequuntur magni dolores eos qui ratione
                  voluptatem sequi nesciunt. Neque porro quisquam est, qui
                  dolorem ipsum quia dolor sit amet, consectetur, adipisci
                  velit, sed quia non numquam eius modi tempora incidunt ut
                  labore et dolore magnam aliquam.
                </TextWrapper>
              </div> */}

              <div className="text-section">
                <Title
                  heading="h3"
                  className="mb4"
                  noUnderline
                  id="vikailmoitukset"
                >
                  Vikailmoitukset ja ongelmatilanteet
                </Title>
                {/* <Title heading="h6" className="mb4" color="near-black">
                  In brief:
                </Title> */}
                <TextWrapper>
                  Huomioi, että asukkaalla on ilmoitusvelvollisuus kaikissa
                  vikatilanteissa välittömästi vian ilmetessä. Vikailmoitukset
                  vastaanottaa huoltoyhtiö.
                </TextWrapper>
                <TextWrapper>
                  Ota ongelmatilanteissa yhteyttä seuraaviin tahoihin:
                </TextWrapper>
                <TextWrapper>Ota yhteyttä huoltoyhtiöön:</TextWrapper>
                <ul className="arrow-list arrow-list--narrow">
                  <li>vikailmoitukset & vahinkotilanteet</li>
                  <li>ovien avaukset</li>
                  <li>ovien avaukset</li>
                  <li>piha-alueiden hoito</li>
                  <li>nimitietojen päivittäminen</li>
                </ul>
                <TextWrapper>Ota yhteyttä vuokranantajaan:</TextWrapper>
                <ul className="arrow-list arrow-list--narrow">
                  <li>avainasiat</li>
                  <li>autopaikat</li>
                  <li>vuokriin ja vastikkeisiin sekä muihin maksuihin liittyen</li>
                  <li>asukasmuutokset</li>
                </ul>
                <TextWrapper>Ota yhteyttä isännöitsijään:</TextWrapper>
                <ul className="arrow-list arrow-list--narrow">
                  <li>taloyhtiön hallintaan liittyvät kysymykset</li>
                  <li>henkilö- tai esinevahingot taloyhtiön alueella</li>
                </ul>
                <TextWrapper className="mb4">
                  Huoltoyhtiön ja isännöitsijän yhteystiedot löydät oman talosi
                  ilmoitustaululta sekä Tuulia Kotien{' '}
                  <a href="#yhteystiedot">verkkosivuilta</a>.
                </TextWrapper>
              </div>

              {/* <div className="text-section">
                <Title
                  heading="h6"
                  className="mb4"
                  noUnderline
                  color="near-black"
                >
                  In addition to the size of the apartment, the following points
                  should also be taken into account:
                </Title>
                <ul className="arrow-list">
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit in voluptate velit esse.
                  </li>
                </ul>
              </div> */}

              <div className="text-section">
                <Title
                  heading="h3"
                  className="mb3 mt4"
                  noUnderline
                  id="yhteystiedot"
                >
                  Huoltoyhtiöiden ja isännöitsijöiden yhteystiedot
                </Title>
                <Title
                  heading="h6"
                  className="mb3 mt4"
                  noUnderline
                  color="near-black"
                >
                  ASOY Lahden Ruotsilanportti, Vuoripojankatu 11
                </Title>
                <ul className="list-simple">
                  <li>
                    <b>Isännöitsijä:</b> Retta Isännöinti, +358 10 228 7145, <a
                      href="https://retta.fi/yhteystiedot/retta-isannointi/"
                      target="_blank" rel="noreferrer"
                    >Retta isännöinti
                    </a>
                  </li>
                  <li>
                    <b>Huoltoyhtiö:</b> Totopro Oy +358 45 152 8115,
                    vikailmoitukset: www.totopro.fi/huoltokutsu
                  </li>
                </ul>

                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  ASOY Lahden Launeenpuisto 2, Vanhanladonkatu 3
                </Title>
                <ul className="list-simple">
                  <li>
                    <b>Isännöitsijä:</b> REIM Lahti Oy,
                    +358 207 438 570, <a href="mailto: lahti@reim.fi">lahti@reim.fi</a>
                  </li>
                  <li>
                    <b>Huoltoyhtiö:</b> Kiinteistöpalvelu Nummela Ay, +358 400
                    714 325, <a href="mailto: huolto@kpnummela.fi">huolto@kpnummela.fi</a>, vikailmoitukset: <a href="https://kpnummela.fi/vikailmoitus/">
                      lomakkeeseen
                    </a>
                  </li>
                </ul>

                {/* <TextWrapper className="mb4">
                  Anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                  error sit voluptatem accusantium doloremque laudantium, totam
                  rem aperiam, eaque ipsa quae ab illo inventore veritatis et
                  quasi architecto beatae vitae dicta sunt explicabo.
                </TextWrapper>

                <ListBenefits /> */}
              </div>
              <div className="text-section">
                <Title heading="h3" className="mb4" id="irtisanominen">
                  Vuokrasopimuksen irtisanominen ja poismuutto
                </Title>
                <TextWrapper>
                  Vuokrasopimuksen irtisanomisen suhteen ei Tuulia Kodin
                  vuokrasopimuksissa ole säädetty erityisehtoja, vaan toimimme
                  kuten AHVL:ssä on säädetty. Vuokralaisen on tehtävä
                  vuokrasopimuksen irtisanomisesta ilmoitus vähintään yhtä
                  kalenterikuukautta ennen sopimuksen päättymistä.
                  Irtisanomisaika lasketaan sen kuukauden viimeisestä päivästä,
                  jolloin irtisanomisilmoitus on toimitettu vuokranantajalle.
                  Vuokra-aika siis päättyy aikaisintaan irtisanomisilmoituksen
                  tekemistä seuraavan kalenterikuukauden viimeisenä päivänä.
                  Vuokrasopimuksen irtisanominen kannattaa tehdä kirjallisesti
                  tältä sivustolta löytyviä vuokranantajan yhteystietoja
                  käyttäen.
                </TextWrapper>
                <TextWrapper>
                  Mikäli vuokrasopimukseen on merkitty useita vuokralaisia,
                  tulee kaikkien sopimuksen allekirjoittajien vahvistaa myös
                  sopimuksen irtisanominen.
                </TextWrapper>
                <TextWrapper>
                  Asunnon vapautuminen ja muuttopäivä on Tuulia Kodin
                  vuokrasopimuksissa yleensä kirjattu sopimukseen erikseen, ja
                  on usein sopimuksen päättymispäivä. Mikäli asiaa ei ole
                  erikseen vuokrasopimukseen kirjattu, on muuttopäivä
                  vuokrasopimuksen päättymisen jälkeinen ensimmäinen arkipäivä.
                  Muuttopäivän aikana asunto on tyhjennettävä ja avaimet
                  palautettava vuokranantajalle.
                </TextWrapper>
                <TextWrapper>
                  Myöhässä palautetusta avaimesta veloitetaan myöhästymismaksu.
                  Mikäli kaikkia avaimia ei palauteta tai asukas on teettänyt
                  luvatta lisäavaimia, vuokranantajalla on oikeus vaihtaa
                  asunnon lukko ja veloittaa kulut asukkaalta.
                </TextWrapper>
                <TextWrapper>
                  Vuokranantaja tarkastaa asunnon heti poismuuton jälkeen.
                  Mikäli asunto ei vastaa vuokranantajan edellyttämää tasoa
                  siisteydeltään ja kunnoltaan, laskutetaan siivous- ja
                  kunnostustyöt suoraan asunnon poismuuttavilta asukkailta.
                  Vakuusmaksu palautetaan asukkaan ilmoittamalle tilille
                  sopimuksen päättymistä seuraavan kuukauden aikana, edellyttäen
                  että asukas on hoitanut vuokranmaksuun ja asunnon luovutukseen
                  liittyvät velvoitteensa. Ennen vakuuden palauttamista
                  suoritetaan vielä vesimaksutasaus, jossa kertyneestä
                  vesimaksusta vähennetään maksetut vesimaksuennakot.
                  Vesimaksutasauksen myötä vuokralainen voi saada
                  rahapalautuksen, tai ennakot ylittävä vesimaksu voidaan
                  vähentää vuokravakuudesta ennen vakuuden palauttamista.
                </TextWrapper>
              </div>
              <div className="text-section">
                <Title heading="h3" className="mb4" id="faq">
                  Usein kysytyt kysymykset
                </Title>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Olen irtisanonut vuokrasopimukseni ja olen muuttamassa pois.
                  Milloin vuokravakuuteni palautetaan?
                </Title>
                <TextWrapper className="mb4">
                  Vuokravakuus palautetaan asunnon vastaanottotarkastuksen ja
                  vesilaskutasauksen suorittamisen jälkeen. Vesilaskutasaus
                  edellyttää vesimittarin lukemista ja paikallisen vesilaitoksen
                  toimittamaa tasauslaskua veteen liittyen. Normaalitilanteessa
                  vuokravakuus on palautettu vuokralaisen tilille viikon
                  kuluessa muutosta.
                </TextWrapper>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Naapurini aiheuttaa melu- ja muita häiriöitä, jotka
                  häiritsevät asumistani. Kenelle ilmoitan häiriöistä?
                </Title>
                <TextWrapper className="mb4">
                  Ilmoituksen oikea kohde on asuintalosi isännöitsijä.
                  Isännöitsijäsi yhteystiedot löydät{' '}
                  <a href="#yhteystiedot">täältä</a>
                </TextWrapper>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Avaimeni on kadonnut. Miten pääsen asuntooni sisälle ja mitä
                  muita toimia minun tulee tehdä?
                </Title>
                <TextWrapper className="mb4">
                  Ota yhteyttä taloyhtiösi isännöitsijään, tiedot löydät{' '}
                  <a href="#yhteystiedot">täältä</a>. Mikäli avain on pysyvästi
                  hävinnyt, kannattaa tästä ilmoittaa myös vuokranantajalle.
                </TextWrapper>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Olen irtisanonut vuokrasopimukseni ja olen muuttamassa pois.
                  Miten avainten palautuksen ja asunnon luovutuksen osalta
                  menetellään?
                </Title>
                <TextWrapper className="mb4">
                  Sovi yhdessä vuokranantajasi kanssa asunnon tarkempi
                  luovutusajankohta. Siivoa asunto, ja mikäli muuta ei ole
                  sovittu, niin jätä avaimesi asunnon keittiön tasolle ja sulje
                  ovi lähtiessäsi. Tuulia Kodit suorittaa asunnon
                  vastaanottotarkastuksen mahdollisimman pian. Mikäli haluat,
                  että vastaanottotarkastus tehdään yhdessä, niin mainitse tästä
                  asuntoa irtisanottaessa.
                </TextWrapper>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Olen muuttamassa pois. Mitä kohteita minun tulee
                  loppusiivouksessa huomioida, jotta varmistan, että asunnon
                  siisteystaso on riittävä ja vuokravakuuteni palautetaan
                  mahdollisimman pian ilman viivästyksiä?
                </Title>
                <TextWrapper className="mb4">
                  Pois muuttavan vuokralaisen siivousohjeen voit löytää{' '}
                  <a href="#muuttosiivous">täältä</a>.
                </TextWrapper>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Minulla on taloudellisia ongelmia, ja en pysty maksamaan
                  seuraavaa vuokraani täysimääräisenä ajallaan. Mitä voin tehdä?
                </Title>
                <TextWrapper className="mb4">
                  Ole yhteydessä vuokranantajaan ja kerro tilanteestasi.
                  Vuokranantajan on hyvä vähintäänkin tietää tulossa olevasta
                  maksuviivästyksestä etukäteen. Joskus voi myös olla
                  mahdollista sopia tilapäisesti poikkeavasta
                  vuokranmaksuaikataulusta tai muusta tilannetta helpottavasta
                  järjestelystä.
                </TextWrapper>
              </div>
              <div className="text-section">
                <Title heading="h3" className="mb4" id="muuttosiivous">
                  Muuttosiivous
                </Title>
                <TextWrapper className="mb4">
                  Poismuuttavan vuokralaisen tärkeimpiin tehtäviin kuuluu
                  kunnollinen muuttosiivous. Näin toimimalla varmistat, että
                  vakuusmaksusi palautuu ilman viivettä ja täysimääräisenä
                  tilillesi, ja toisaalta uuden asukkaan on mukava muuttaa
                  siistiin asuntoon. Muuttosiivous on hyvä tehdä ajoissa ennen
                  muuttoa, sillä muuttopäivä on tarkoitettu siivoamisen sijasta
                  tavaroiden siirtämiseen!
                </TextWrapper>
                <TextWrapper className="mb4">
                  Oheiselle pois muuttavan vuokralaisen tarkistuslistalle on
                  koottu asioita, jotka on erityisen tärkeä muistaa huomioida
                  poismuuton yhteydessä. Siivouksessa olevat puutteet ja
                  asunnossa ilmenevät ns. normaalin kulumisen ylittävät viat
                  johtavat aina ammattilaisen suorittamaan työhön, jonka
                  muodostamat kustannukset vähennetään vuokravakuudesta.
                </TextWrapper>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Keittiö
                </Title>
                <ul className="arrow-list arrow-list--narrow">
                  <li>
                    Sammuta ja sulata jääkaappi ja pakastin. Älä jätä laitteita
                    sulamaan yksin, ja huolehdi kertyvästä sulamisvedestä. Pese
                    kylmäkalusteet huolellisesti pesuaineella. Jätä jääkaapin ja
                    pakastimen ovi sulamisen jälkeen auki.
                  </li>
                  <li>
                    Irrota ja puhdista liesikuvan rasvasuodatin harjalla. Pyyhi
                    pölyt poistoilmaventtiilistä.
                  </li>
                  <li>
                    Pese liesi sienellä ja pesuaineella. Käytä keittolevyjen
                    puhdistamiseen tarvittaessa sokeripalaa, skrapaa tai
                    teräsvillaa. Puhdista uuni sekä uuninpellit
                    uuninpesuaineella. Muista imuroida uunin taustat ja
                    reunukset vetämällä liesi irti seinästä. Käytä rättiä
                    seinän, kaappien ja lieden reunoissa olevien roiskeiden
                    siistimiseen. Mikäli irrotat omistamasi astianpesukoneen,
                    huolehdi veden katkaisusta ja sekä tulo- että
                    poistovesiliitäntöjen tulppauksesta.
                  </li>
                  <li>
                    Puhdista erityisesti keittiön tasot ja kaapistot
                    huolellisesti mahdollisesti kertyneistä ruokatahroista ja
                    roiskeista.
                  </li>
                </ul>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Kylpyhuone ja WC
                </Title>
                <ul className="arrow-list arrow-list--narrow">
                  <li>
                    Pese WC-istuin perusteellisesti, käytä harjaa sekä
                    tarkoitukseen varattua pesuainetta.
                  </li>
                  <li>
                    Puhdista käsienpesuallas, suihkutilojen seinät ja
                    mahdollinen lasinen väliseinä, lattiat ja vesihanat
                    huolellisesti. Käytä tarkoitukseen varattuja pesuaineita.
                    Jos käytät lasiseinän puhdistamisessa apuna skraappaa,
                    muista kostuttaa puhdistettava pinta ja työvälineet
                    saippuavedellä naarmujen välttämiseksi.
                  </li>
                  <li>
                    Pese lattiakaivot irrottamalla kansi ja puhdistamalla kannen
                    lisäksi hajulukko hiuksista ja muusta lukkoon kerääntyneestä
                    materiaalista.
                  </li>
                  <li>
                    Mikäli huoneistossasi on sauna, pese saunan lauteet ja
                    seinät. Käytä tarkoitukseen varattua puhdistusaineitta, ja
                    muista huuhdella pinnat pesun jälkeen.
                  </li>
                </ul>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Muu huoneisto
                </Title>
                <ul className="arrow-list arrow-list--narrow">
                  <li>Tyhjennä, puhdista ja pyyhi kaapit, hyllyt ja ovet.</li>
                  <li>Imuroi ja pese kaikkien tilojen lattiat.</li>
                  <li>
                    Puhdista ikkunoiden sisäpinnoilta selvä lika ja roiskeet.
                    Seuraava asukas arvostaa varmasti, mikäli peset ikkunat
                    kokonaisuudessaan.
                  </li>
                  <li>Pyyhi ovet ja ovien karmit.</li>
                  <li>Puhdista patteri, lattialistat ja kukkalaudat.</li>
                  <li>Tyhjennä ja siivoa parveke sekä oma varastokoppisi</li>
                  <li>
                    Kalusteet, patjat, televisiot ym jäte on itse toimitettava
                    kierrätykseen, eikä näitä tavaroita voi jättää taloyhtiön
                    jätepisteeseen.
                  </li>
                  <li>
                    Huolehdi, että asunto on tyhjennetty kaikista tavaroistasi.
                    Mikäli näin on sovittu, jätä avain huoneistoon ja lukitse
                    ovi.
                  </li>
                </ul>
              </div>
              <div className="text-section">
                <Title heading="h3" className="mb5" noUnderline id="laki">
                  Laki asuinhuoneiston vuokrauksesta (AHVL 481/95), eräitä
                  säännöksiä
                </Title>

                <ul className="list-decimal-title">
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Huoneiston hallinnan luovutus
                    </Title>
                    <TextWrapper>
                      Vuokralainen saa käyttää asuinhuoneistoa omana tai
                      yhteisenä asuntona puolisonsa ja perheeseensä kuuluvien
                      lasten kanssa. Jollei siitä aiheudu vuokranantajalIe
                      huomattavaa haittaa tai häiriötä. vuokralainen saa käyttää
                      huoneistoa yhteisenä asuntona myös lähisukulaisensa tai
                      puolisonsa lähisukulaisen kanssa taikka luovuttaa
                      alivuokrauksena tai muutoin enintään puolet huoneistosta
                      toisen käytettäväksi asumiseen. Muussa tapauksessa
                      vuokralainen ei kuitenkaan saa ilman lupaa luovuttaa
                      huoneistoa tai sen osaa toisen käyttöön. Asuinhuoneiston
                      hallinnan väliaikaisesta luovutuksesta säädetään AHVL 18
                      §.ssä.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Vuokraoikeuden siirto
                    </Title>
                    <TextWrapper>
                      Vuokralainen ei saa ilman vuokranantajan lupaa siirtää
                      vuokraoikeuttaan. Hän voi kuitenkin siirtää ilman lupaa
                      vuokraoikeuden huoneistossa asuvalle puolisolleen,
                      perheeseen kuuluvalle lapselle tai jommankumman puolison
                      vanhemmalle, jollei vuokranantajalla ole perusteltua syytä
                      vastustaa vuokraoikeuden siirtoa. Vuokraoikeuden
                      siirtämisestä vuokralaisen on välittömästi ilmoitettava
                      kirjallisesti vuokranantajalIe.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Vuokralaisen velvollisuudet huoneistoa käytettäessä
                    </Title>
                    <TextWrapper>
                      Vuokralaisen on hoidettava huoneistoa huolellisesti.
                      Vuokranantaja ja vuokralainen voivat sopia huoneistossa
                      suoritettavista korjaus- tai muutostöistä taikka
                      hoitotoimenpiteistä. Vuokralainen on velvollinen
                      korvaamaan vuokranantajalIe tuottamuksellisesti
                      aiheuttamansa vahingon. Vuokralaisen on viipymättä
                      ilmoitettava vuokranantajalIe huoneiston
                      vahingoittumisesta tai puutteellisuudesta Ilmoitus on
                      tehtävä heti, jos korjaus on vahingon suurenemisen
                      estämiseksi suoritettava viipymättä.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Vuokranantajan vastuu huoneiston kunnosta
                    </Title>
                    <TextWrapper>
                      Vuokranantaja vastaa siitä, että huoneisto on
                      vuokrasuhteen alkaessa ja sen aikana sellaisessa kunnossa
                      kuin vuokralainen huoneiston iän, alueen huoneistokannan
                      ja muuta paikalliset olosuhteet huomioon ottaen voi
                      kohtuudella vaatia, jollei muusta kunnosta ole erikseen
                      sovittu. Sopia voidaan myös siitä, että vuokralainen
                      huolehtii vuokrasuhteen johdosta vuokralaisen käytössä
                      olevien tilojen ja laitteiden kunnossapidosta tai
                      kiinteistölIe kuuluvista velvoitteista.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Huoneiston valvonta
                    </Title>
                    <TextWrapper>
                      Jos huoneisto jätetään käyttämättä pitkäksi aikaa,
                      vuokralaisen on ilmoitettava siitä vuokranantajalIe ja
                      varattava tälle mahdollisuus päästä huoneistoon.
                      Huoneiston kunnon ja hoidon valvomista varten vuokralaisen
                      on viivytyksettä sopivana aikana päästettävä vuokranantaja
                      huoneistoon. Jos huoneisto on tarkoitus myydä tai se on
                      uudelleen vuokrattavissa, vuokranantajalla on oikeus
                      näyttää huoneistoa vuokranantajalIe ja vuokralaiselle
                      sopivana aikana. Vuokranantajan oikeudesta suorittaa
                      korjaus- tai muutostöitä on säännelty lain AHVL 20 §.ssä.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Vuokran suuruus ja korottaminen
                    </Title>
                    <TextWrapper>
                      Asuinhuoneiston vuokran määrä voidaan sopia vapaasti.
                      Vuokran on kuitenkin oltava kohtuullinen. Vuokraa voidaan
                      korottaa osapuolten välisen sopimuksen mukaisesti.
                      Vuokrasopimukseen voidaan ottaa esim indeksiehto.
                      Edellytyksenä on, että sopimus on toistaiseksi
                      voimassaoleva tai tehty vähintään kolmeksi vuodeksi.
                      Vuokran korottaminen kesken vuokrasopimuksessa sovitun
                      vuokrakauden ei ole mahdollista ilman vuokralaisen
                      suostumusta. Jos korotuksesta ei päästä sopimukseen,
                      vuokranantaja voi irtisanoa sopimuksen vuokran
                      korottamiseksi kohtuulliselle tasolle.
                    </TextWrapper>
                    <TextWrapper>
                      Vuokran tarkistamisesta voidaan sopia ottaen huomioon,
                      mitä indeksiehdon käytön rajoittamisesta annetussa laissa
                      (1222/94) säädetään. Jos on sovittu, että vuokranantaja
                      saa vuokrasuhteen aikana yksipuolisesti määrätä vuokran
                      korotuksen ajankohdan tai määrän, tällainen sopimusehto on
                      mitätön, jos ei ole myös sovittu perusteesta, jonka mukaan
                      vuokraa saadaan korottaa sopimussuhteen aikana.
                      Vuokranantajan on ennen korotetun vuokran voimaantuloa
                      kirjallisesti ilmoitettava vuokralaiselle uusi vuokra ja
                      sen voimaantuloajankohta.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Jälleenvuokraus
                    </Title>
                    <TextWrapper>
                      Jälleenvuokrauksesta on kysymys silloin, kun vuokralainen
                      vuokranantajan luvalla vuokraa koko asuinhuoneiston
                      edelleen. Ensivuokrasuhteeksi kutsutaan alkuperäisen
                      vuokranantajan ja vuokralaisen välistä vuokrasuhdetta.
                      Vuokralaisen luovuttaessa koko huoneiston hallinnan
                      edelleen syntyy jälleenvuokrasuhde. Jälleenvuokrasuhteen
                      vuokranantajan on ilmoitettava vuokralaiselleen oma
                      vuokranantajansa sekä tälle jälleenvuokrasuhde ja sen
                      vuokralainen. Lisäksi jälleenvuokrasuhteen vuokranantajan
                      on ilmoitettava omalle vuokralaiselleen
                      ensivuokrasopimuksen ehdot.
                    </TextWrapper>
                    <TextWrapper>
                      Vuokrasuhteen purkamisesta tai irtisanomisesta taikka
                      muusta vuokrasuhdetta koskevasta ilmoituksesta
                      vuokranantajan on heti ilmoitettava sille jäileenvuokra-
                      tai ensivuokrasuhteen osapuoleile, johon irtisanomista,
                      purkamista tai ilmoitusta ei ole kohdistettu.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Vuokrasopimuksen irtisanominen
                    </Title>
                    <TextWrapper>
                      Irtisanomisilmoitus on toimitettava vuokralaiselle
                      todistettavasti kirjallisesti. Ilmoituksessa on mainittava
                      irtisanomisen peruste ja vuokrasuhteen päättymisajankohta,
                      ks. AHVL 54 §.
                    </TextWrapper>
                    <TextWrapper>
                      Mitään erityistä irtisanomisperustetta ei edellytetä.
                      Ainoana rajana on se, että irtisanomisperuste ei saa olla
                      vuokrasuhteissa noudatetun hyvän tavan vastainen tai
                      vuokralaisen kannalta kohtuuton.
                    </TextWrapper>
                    <TextWrapper>
                      Vuokranantajan irtisanomisaika on kolme kuukautta, ja jos
                      vuokrasuhde on välittömästi ennen irtisanomista kestänyt
                      yhtäjaksoisesti vähintään yhden vuoden, kuusi kuukautta.
                      Vuokralainen voi saada välillistä irtisanomissuojaa,
                      vahingonkorvauksen muodossa tiianteesta riippuen, ks. AHVL
                      57 §. Oikeus korvaukseen on siiloin, kun vuokranantajan
                      suorittamaa irtisanomista ei voida pitää vuokrasuhteissa
                      noudatettavan hyvän tavan mukaisena. Jos vuokranantajaila
                      on hyväksyttävä syy irtisanomiseen, kuten vuokran
                      tarkistaminen kohtuulliselle tasolle, vuokralaisella ei
                      ole oikeutta korvaukseen.
                    </TextWrapper>
                    <TextWrapper>
                      Vuokralainen voi saada välitöntäkin irtisanomissuojaa
                      laissa rajatuissa tilanteissa, ks AHV 56§. Tuomioistuin
                      voi vuokralaisen kanteesta julistaa irtisanomisen
                      tehottomaksi, jos tarkoituksena on korottaa vuokra
                      kohtuuttomaile tasolle tai peruste on muutoin kohtuuton
                      eikä vuokranantajaila ole irtisanomiseileen hyväksyttävää
                      syytä.
                    </TextWrapper>
                    <TextWrapper>
                      Vuokralaisen irtisanomisaika on yksi kuukausi.
                      Vuokralaisen irtisanominen on toimitettava todistettavasti
                      kirjailisesti vuokranantajaile.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Vuokrasopimuksen purkaminen
                    </Title>
                    <TextWrapper>
                      Vuokranantajan tai vuokralaisen, joka haluaa käyttää
                      laissa säädettyä oikeutta vuokrasopimuksen purkamiseen, on
                      annettava kirjailinen purkamisilmoitus. Siinä on
                      mainittava purkamisperuste ja vuokrasuhteen päättymisen
                      ajankohta, jos vuokrasuhde halutaan päättyväksi myöhemmin
                      kuin välittömästi purkamisilmoituksen tiedoksiannosta.
                    </TextWrapper>
                    <TextWrapper>
                      Purkamisilmoitus on annettava tiedoksi haasten
                      tiedoksiantamisesta säädetyssä järjestyksessä tai muuten
                      todistettavasti.
                    </TextWrapper>
                    <TextWrapper>
                      Vuokranantajan purkuperusteista on määräykset AHVL 8, 38,
                      40, 49 ja 61 §.ssä ja vuokralaisen purkuperusteista AHVL
                      8, 16, 20 ja 63 §.ssä. Vuokranantajan on AHVL 62 §ssä
                      mainituissa tapauksissa annettava ennen purkamisoikeuden
                      käyttämistä vuokralaiselle kirjailinen varoitus.
                    </TextWrapper>
                  </li>
                  <li>
                    <Title heading="h6" className="mb3" noUnderline>
                      Vuokrasopimuksen päättymis- ja muuttopäivä
                    </Title>
                    <TextWrapper>
                      Vuokrasopimuksen päättymispäivä on se päivä, jona
                      vuokrasuhde päättyy sopimuksen irtisanomisen tai
                      purkamisen johdosta.
                    </TextWrapper>
                    <TextWrapper>
                      Muuttopäivä on vuokrasopimuksen päättymispäivää lähinnä
                      seuraava arkipäivä.
                    </TextWrapper>
                  </li>
                </ul>
              </div>

              {/* <div className="text-section">
                <Title heading="h3" className="mb4" noUnderline>
                  Also check out these documents:
                </Title>

                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Property Manager Certificate
                </Title>
                <TextWrapper className="mb4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Sunt, placeat. Nulla illo minima dolores eum quod. Hic,
                  officia. Hic quam quos voluptatibus at quia excepturi fugit
                  labore tenetur nam quibusdam!
                </TextWrapper>

                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  Report of the Board of Directors
                </Title>
                <TextWrapper className="mb4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Sunt, placeat. Nulla illo minima dolores eum quod. Hic,
                  officia. Hic quam quos voluptatibus at quia excepturi fugit
                  labore tenetur nam quibusdam!
                </TextWrapper>

                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  PTS
                </Title>
                <TextWrapper className="mb4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Sunt, placeat. Nulla illo minima dolores eum quod. Hic,
                  officia. Hic quam quos voluptatibus at quia excepturi fugit
                  labore tenetur nam quibusdam!
                </TextWrapper>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4" noUnderline>
                  What to consider about the condition of the apartment?
                </Title>
                <Title
                  heading="h6"
                  className="mb4"
                  noUnderline
                  color="near-black"
                >
                  Surface repair or ready to move home?
                </Title>

                <TextWrapper className="mb4">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Sunt, placeat. Nulla illo minima dolores eum quod. Hic,
                  officia. Hic quam quos voluptatibus at quia excepturi fugit
                  labore tenetur nam quibusdam!
                </TextWrapper>
                <TextWrapper>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Sunt, placeat. Nulla illo minima dolores eum quod. Hic,
                  officia. Hic quam quos voluptatibus at quia excepturi fugit
                  labore tenetur nam quibusdam!
                </TextWrapper>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4" noUnderline>
                  Do I need to do a moisture survey in my apartment?
                </Title>

                <TextWrapper>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Sunt, placeat. Nulla illo minima dolores eum quod. Hic,
                  officia. Hic quam quos voluptatibus at quia excepturi fugit
                  labore tenetur nam quibusdam!
                </TextWrapper>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb3">
                  Finish your finances before buying an investment home
                </Title>
                <TextWrapper className="mb4">
                  Anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                  error sit voluptatem accusantium doloremque laudantium, totam
                  rem aperiam, eaque ipsa quae ab illo inventore veritatis et
                  quasi architecto beatae vitae dicta sunt explicabo.
                </TextWrapper>

                <TextWrapper className="mb4">
                  Anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                  error sit voluptatem accusantium doloremque laudantium, totam
                  rem aperiam, eaque ipsa quae ab illo inventore veritatis et
                  quasi architecto beatae vitae dicta sunt explicabo.
                </TextWrapper>

                <img src={testImage} alt="" className="w-100 mb6 mb4-sm" />

                <Title heading="h3" className="mb4" noUnderline>
                  Investment home loan negotiations
                </Title>
                <Title
                  heading="h6"
                  className="mb3"
                  noUnderline
                  color="near-black"
                >
                  My finances are in order before I go to the bank
                </Title>
                <TextWrapper>
                  Anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                  error sit voluptatem accusantium doloremque laudantium, totam
                  rem aperiam, eaque ipsa quae ab illo inventore veritatis et
                  quasi architecto beatae vitae dicta sunt explicabo.
                </TextWrapper>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4">
                  Make an accurate plan for the cost of your investment home
                </Title>
                <TextWrapper className="mb4">
                  Anim id est laborum. Sed ut perspiciatis unde omnis iste natus
                  error sit voluptatem accusantium doloremque laudantium, totam
                  rem aperiam, eaque ipsa quae ab illo inventore veritatis et
                  quasi architecto beatae vitae dicta sunt explicabo.
                </TextWrapper>

                <ul className="arrow-list mb6 mb4-sm">
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit in voluptate velit esse.
                  </li>
                  <li>
                    Cillum dolore eu fugiat nulla pariatur excepteur sint
                    occaecat cupidatat non proident.
                  </li>
                </ul>

                <Title heading="h3" className="mb4">
                  Do not compete for a loan at several banks
                </Title>
                <TextWrapper className="mb4">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>
                <a href="#test" className="blue f7">
                  Read more about applying for a mortgage.
                </a>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4" noUnderline>
                  What's the leverage of a miracle?
                </Title>
                <TextWrapper className="mb5">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>

                <Title heading="h3" className="mb5" noUnderline>
                  Tax ABC for investment home buyer
                </Title>
                <ul className="list-decimal-title">
                  <li>
                    <Title
                      heading="h4"
                      className="mb4"
                      noUnderline
                      fontWeight="light"
                      color="near-black"
                    >
                      The transfer tax shall be payable after the purchase of
                      the investment property
                    </Title>
                    <TextWrapper className="mb5">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                    <Title heading="h6" noUnderline color="near-black">
                      The transfer tax shall be payable after the purchase of
                      the investment property
                    </Title>
                  </li>
                  <li>
                    <Title
                      heading="h4"
                      className="mb4"
                      noUnderline
                      fontWeight="light"
                      color="near-black"
                    >
                      Rental income is income subject to capital duty
                    </Title>
                    <TextWrapper className="mb5">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>

                    <ul className="arrow-list">
                      <li>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua.
                      </li>
                      <li>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse.
                      </li>
                      <li>
                        Cillum dolore eu fugiat nulla pariatur excepteur sint
                        occaecat cupidatat non proident.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Title
                      heading="h4"
                      className="mb4"
                      noUnderline
                      fontWeight="light"
                      color="near-black"
                    >
                      Take advantage of tax deductions
                    </Title>
                    <TextWrapper>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                  </li>
                </ul>

                <Title
                  heading="h4"
                  className="mb6 mb4-sm"
                  noUnderline
                  color="near-black"
                >
                  Six expenses to be deducted from rental income:
                </Title>

                <ul className="list-decimal-title">
                  <li>
                    <Title
                      heading="h5"
                      className="mb4"
                      noUnderline
                      color="near-black"
                    >
                      Care and capital consideration
                    </Title>
                    <TextWrapper>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                  </li>
                  <li>
                    <Title
                      heading="h5"
                      className="mb4"
                      noUnderline
                      color="near-black"
                    >
                      Repair costs
                    </Title>
                    <TextWrapper>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                  </li>
                  <li>
                    <Title
                      heading="h5"
                      className="mb4"
                      noUnderline
                      color="near-black"
                    >
                      Reduction of acquisition costs of movable property
                    </Title>
                    <TextWrapper>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                  </li>
                  <li>
                    <Title
                      heading="h5"
                      className="mb4"
                      noUnderline
                      color="near-black"
                    >
                      Deductions for Furnished Rentals
                    </Title>
                    <TextWrapper>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                  </li>
                  <li>
                    <Title
                      heading="h5"
                      className="mb4"
                      noUnderline
                      color="near-black"
                    >
                      The interest on an investment home loan can be deducted
                      from the return on capital
                    </Title>
                    <TextWrapper>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                  </li>
                  <li>
                    <Title
                      heading="h5"
                      className="mb4"
                      noUnderline
                      color="near-black"
                    >
                      Losses from Leasing Operations
                    </Title>
                    <TextWrapper>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                      Tenetur fugit porro, et labore tempore illo ducimus
                      debitis? Fugit, quisquam et iusto, enim assumenda saepe
                      inventore quis aperiam dolorem, debitis est!
                    </TextWrapper>
                  </li>
                </ul>

                <Title
                  heading="h5"
                  className="mb4"
                  noUnderline
                  color="near-black"
                >
                  Read the Tax Administration's comprehensive and up-to-date
                  information package on rent reductions .
                </Title>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4" noUnderline id="calving">
                  Investment apartment for calving - measures after purchase
                </Title>
                <ul className="arrow-list mb6 mb4-sm">
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit in voluptate velit esse.
                  </li>
                  <li>
                    Cillum dolore eu fugiat nulla pariatur excepteur sint
                    occaecat cupidatat non proident.
                  </li>
                </ul>

                <Title heading="h3" className="mb4" noUnderline>
                  Rental of investment accommodation
                </Title>
                <TextWrapper className="mb4">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>
                <ul className="list-decimal">
                  <li>
                    <a href="#test">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed
                    </a>
                  </li>
                  <li>
                    <a href="#test">
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    </a>
                  </li>
                  <li>
                    <a href="#test">
                      Cillum dolore eu fugiat nulla pariatur excepteur sint
                      occaecat
                    </a>
                  </li>
                </ul>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4" noUnderline>
                  Duties of the Landlord
                </Title>
                <TextWrapper className="mb5">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>

                <Title heading="h6" className="mb4" noUnderline>
                  It is good to agree on a lease at least
                </Title>
                <ul className="arrow-list">
                  <li>Type of tenancy (fixed-term or open-ended)</li>
                  <li>termination of the contract</li>
                  <li>security</li>
                  <li>other costs of the home, such as water</li>
                  <li>other controlled spaces, such as a basement or loft</li>
                  <li>home insurance; and</li>
                  <li>of rent</li>
                </ul>

                <TextWrapper className="mb6 mb4-sm">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper> */}

              {/* <div className="text-section">
                <Title heading="h3" className="mb4">
                  Tenant Responsibilities
                </Title>
                <TextWrapper className="mb4">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>
                <TextWrapper className="mb4-sm mb6 mb4-sm blue f9-sm f8">
                  Read more about tenant responsibilities in the Tuulia Tenant
                  Guide .
                </TextWrapper>

                <Title heading="h3" className="mb4">
                  Landlord insurance provides security
                </Title>
                <TextWrapper>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4">
                  Sale of investment home
                </Title>
                <TextWrapper>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>
              </div>

              <div className="text-section">
                <Title heading="h3" className="mb4">
                  The broker or the sales themselves
                </Title>
                <TextWrapper className="mb6 mb4-sm">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Tenetur fugit porro, et labore tempore illo ducimus debitis?
                  Fugit, quisquam et iusto, enim assumenda saepe inventore quis
                  aperiam dolorem, debitis est!
                </TextWrapper>

                <ul className="list list-links mb4">
                  <li>
                    <a href="#test">www.finlex.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.vuokranantajat.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.sijoittaja.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.asuntosalkunrakentaja.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.kiinteistolehti.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.isannointiliitto.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.kiinteistoliitto.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.sijoitusasunnot.com</a>
                  </li>
                  <li>
                    <a href="#test">www.yit.fi</a>
                  </li>
                  <li>
                    <a href="#test">www.vero.fi</a>
                  </li>
                </ul>
              </div>*/}
            </div>

            <div className="order-1 order-2-l w-100 w-30-l mb6 mb0-l">
              <div
                className="form-shadow bg-white br4 overflow-hidden sticky-lg top-1"
                // style={{ maxWidth: 360 }}
              >
                <div className="ttu f8-sm f7-m f6-l fw6 tc bg-green white pa3 pa4-l">
                  Sisältö
                </div>
                <div className="pa4">
                  <ul className="list-pluses mb4">
                    {headings.map(h => {
                      return (
                        <li key={h.id}>
                          <AnchorLink
                            href={`#${h.id}`}
                            className="no-underline underline-hover db"
                          >
                            {h.innerHTML}
                          </AnchorLink>
                        </li>
                      )
                    })}
                  </ul>
                  <Button to="/yhteystiedot" size="sm" className="db w-100-l">
                    Ota yhteyttä
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Resident
