import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import classNames from 'classnames'

import './styles.css'

const Button = ({
  children,
  to,
  className,
  onClick,
  color = 'green',
  size = 'lg',
  type = 'button',
  withShadow
}) => {
  const classes = classNames(
    {
      'butn dib b--solid bw1 ttu no-underline tc': true,
      'josefin lh-copy pointer relative fw6': true,
      'butn--green bg-green b--green white': color === 'green',
      'butn--white bg-white b--light-gray gray': color === 'white',
      'butn--large f12-sm f11-m f10-l tracked': size === 'lg',
      'butn--middle f13-sm f12-m f11-l': size === 'md',
      'butn--small f14-sm f13-m f12-l': size === 'sm',
      'butn--shadow': withShadow
    },
    className
  )

  if (to) {
    return (
      <Link
        to={to}
        className={`${classes} inline-flex items-center justify-center`}
      >
        {children}
      </Link>
    )
  }

  return (
    <button onClick={onClick} className={classes} type={type}>
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default Button
